.FarmingPracticesWrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center  ;
}
.farmingPractice{
    width: 300px;
    height: 280px;
}
.farmPracticeImg{
    width: 300px;
    height: 280px;
}
.practiceName{
    width: 100%;
    height: 100%;
    z-index:2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
}
.transperentLayer{
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(8, 7, 7, 0.2);
}
@media screen and (min-width:948px) and (max-width:1260px){
    .farmingPractice{
        width: 260px;
        height: 270px;
    }
    .farmPracticeImg{
        width: 260px;
        height: 270px;
    }
    .practiceName{
        font-size: 23px;
    }
}