
.footerWrapper{
    width: 100%;
}
.footerLeaves{
    bottom: 0;
    right: 0;
    height: 420px;
    width: 320px;
}
.cultivatingImg{
    width: 30%;
}
.letsConnect{
    width:35%;
}
.letsConnect h1{
    font-size: 35px;
    line-height: 1.3;
    font-weight: 600;
    font-family: "Martel", serif;
}
.connectForm div input{
    background-color: #E6E6E6;
    border-radius:7px;
    margin: 10px;
    padding: 7px;
    width: 270px;
}
.connectFormBtn{
    background-color: #97EF79;
    width: 270px;
    border-radius:7px;
    margin: 10px;
    padding: 5px;
}
@media screen and (max-width:767px) and (min-width:0px) {
    .footerLeaves{
        display: none;
    }
    .farmerCultivateImg{
        display: none;
    }
    .letsConnect h1{
        font-size: 18px;
    }
    .letsConnect{
        width:90%;
    }
    .cultivatingImg{
        width: 0;
    }
}
@media screen and (max-width:1023px) and (min-width:768px) {
    .footerLeaves{
        height: 320px;
        width: 220px;
    }
    .farmerCultivateImg{
        width: 200px;
    }
    .letsConnect h1{
        font-size: 18px;
    }
    .letsConnect{
        width:50%;
    }
    .cultivatingImg{
        width: 25%;
    }
    .connectForm div input{
        width: 250px;
    }
    .connectFormBtn{
        width: 250px;
    }
}
@media screen and (max-width:1440px) and (min-width:1024px) {
    .footerLeaves{
        height: 350px;
        width: 250px;
    }
    .farmerCultivateImg{
        width: 300px;
    }
    .letsConnect h1{
        font-size: 18px;
    }
    /* .letsConnect{
        width:45%;
    }
    .cultivatingImg{
        width: 35%;
    } */
    .connectForm div input{
        width: 250px;
    }
    .connectFormBtn{
        width: 250px;
    }
}