.backButtonj {
  border-radius: 4px;
  color: rgb(0, 0, 0);
  background-color: white;
  border: 2px black solid;
  left: 0;
  top: 120px;
  margin-left: 30px;
}
.backButtonj:hover {
  color: white;
  background-color: black;
}

.jobEditor {
  margin-left: 50px;
  min-width: 600px;
  max-width: 1000px;
  padding: 10px;
  border: 1px solid rgb(233, 221, 221);
  box-shadow: 1px 2px 5px;
  background-color: rgb(228, 221, 221);
  border-radius: 10px;
}
.textEditor {
  margin-top: 20px;
}
.jobEditorBtn {
  top: 100px;
  right: 20px;
}
@media screen and (min-width: 992px) {
  .jobEditor {
    width: 800px;
  }
}
@media screen and (min-width: 1200px) {
  .jobEditor {
    width: 900px;
  }
}