.questions>div div{
    font-weight: 400;
    font-family: "Quicksand", sans-serif;
}
.knowledgeOnGoodPractice{
        width: 55%;
        font-family: "New Tegomin", serif;
}
.questionsPhrase{
    background-color: #C4FF78;
}
.questionImg{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
/*QuestionOne Css*/
.q1Description{
    width: 50%;
    font-size: 18px;
    text-align: center;
    font-family: "Quicksand", sans-serif;
}

/*How to Implement ?*/
.howToImplementImg{
    width: 60%;
    height: auto;
}
.howToImpWrapp{
    width: 30%;
}
.howToImpDescWrapp{
    width: 40%;
    font-size: 18px;
    font-family: "Quicksand", sans-serif;
}
.HowToImplementHeading{
    padding: 20px;
    margin: 20px;
    margin-left: 200px;
}

/*Advantages and Disadvantages */
.advantagesDisavantagesWrapper{
    background-color:#F4F4F4 ;
}
.advantages,.disadvantages{
    width: 35%;
    border-radius: 15px;
    box-shadow: -1px 1px 9px -2px rgba(201, 201, 201, 0.75);
-webkit-box-shadow: -1px 1px 9px -2px rgba(201, 201, 201, 0.75);
-moz-box-shadow: -1px 1px 9px -2px rgba(201, 201, 201, 0.75);
font-family: "Quicksand", sans-serif;
}
.underline{
    margin-left:30px;
    width: 50%;
    height: 2px;
    background-color: black;
    margin-bottom: 30px;
}
.advDisImg{
    width: 100px;
    height: 100px;
    bottom:-50px;
    right: 0;
}

/*Myths*/
.myths{
    border-radius: 25px;
    width: 80%;
    box-shadow: -1px 1px 9px -2px rgba(201, 201, 201, 0.75);
    -webkit-box-shadow: -1px 1px 9px -2px rgba(201, 201, 201, 0.75);
    -moz-box-shadow: -1px 1px 9px -2px rgba(201, 201, 201, 0.75);
}
.mythsLeavesImg{
    top:0;
    right:0;
    width: 20%;
    height: 100%;
}.mythPoints{
    font-family: "Quicksand", sans-serif;
}

@media screen and (max-width:767px) and (min-width:0px) {
    .knowledgeOnGoodPractice{
        width: 100%;
       
}
.knowledgeOnGoodPractice h1{
    font-size: 20px;
    text-align: center;
}
/*questionOne responsive*/

.q1Description{
    width: 100%;
    text-align: left;
    font-size: 16px;
}
.q1Heading{
    font-size:22px ;
}
/*how to implement in farming*/
.howToImplementImg{
    width: 100%;
    height: auto;
}
.howToImpWrapp{
    width: 90%;
}
.howToImpDescWrapp{
    width: 90%;
    font-size: 16px;
    padding-top: 20px;
}
.HowToImplementHeading{
    margin-left: 10px;
}
.HowToImplementHeading h1{
    font-size: 22px;
    
}
.HowToImpWrapper{
    flex-direction: column;
    align-items: center;
}


/*Advantages*/
.advantagesDisavantages{
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.advDisImg{
    width: 80px;
    height: 80px;
    bottom:-30px;
    right: 0;
}
.advantages,.disadvantages{
    width: 95%;
}
/*Myths*/

.mythsLeavesImg{
    top:0;
    height: auto;
}

}
@media screen and (max-width:1440px) and (min-width:768px) {
    .knowledgeOnGoodPractice{
        width: 80%;
       
}
.knowledgeOnGoodPractice h1{
    font-size: 1.8rem;
}

/*questionOne responsive*/

.q1Description{
    width: 100%;
    font-size: 18px;
}
.q1Heading{
    font-size:25px ;
}

.howToImplementImg{
    width: 100%;
    height: auto;
}
.howToImpWrapp{
    width: 35%;
}
.howToImpDescWrapp{
    width: 45%;
    font-size: 16px;
    padding: 0px 20px;
}
.HowToImplementHeading{
    margin-left: 10px;
}
.HowToImplementHeading h1{
    font-size: 22px;
}


.advantages,.disadvantages{
    width: 40%;
}
/*myths*/
.mythsLeavesImg{
    top:0;
    height: auto;
}
}