/* General Reset */



.loading,
.error {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
}

.backBtnProfile {
  top: 100px;
  background-color: #4CAF50;
  padding: 5px 10px;
}

.editProfileImg {
  width: 20px;
  height: 20px;
}

.editBtnWrapper {
  width: 40px;
  height: 40px;
  border-left: 0px;
  border-bottom: 0px;
  border-top: 0px;
  border-right: 0px;
  border-top-right-radius: 10px;
  background-color: #9fdd4ed7;
}



.editBtnWrapper:hover::after {
  content: "Edit Profile";
  position: absolute;
  bottom: -40px;
  /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  transition: opacity 0.3s ease-in-out;
}
.backBtnProfile:hover::after{
  content: "Back";
  position: absolute;
  bottom: -40px;
  /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  transition: opacity 0.3s ease-in-out;
}

.user-profile-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.user-profile-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  /* overflow: hidden; */
  font-family: "Inter", sans-serif;
}

.user-profile-header {
  text-align: center;
  padding: 20px;
  background-color: #4CAF50;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  color: white;
}

.user-profile-picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 20px;
  border: 5px solid white;
  cursor: pointer;
}

.user-name {
  font-size: 24px;
  margin: 0;
}

.user-profile-details {
  padding: 20px;
  background-color: #f9f9f9;
  border-top: 1px solid #eee;
}

.user-profile-details p {
  margin: 10px 0;
  font-size: 16px;
}

.user-blogs {
  padding: 20px;
}

.user-blogs h2 {
  margin-bottom: 20px;
}

.blogs-list {
  list-style: none;
}

.blog-item {
  margin-bottom: 10px;
}

.blog-link {
  text-decoration: none;
  color: #0073e6;
  font-weight: bold;
}

.blog-link:hover {
  text-decoration: underline;
}
.editTransLayer{
  width: 30px;
  height: 30px;
  cursor:pointer;
  background-color: rgba(0, 0, 0, 0.5);

}
.editTransLayer:hover::after{
  content: "Edit Profile";
position: absolute;
bottom: -40px;
/* Adjust as needed */
left: 50%;
transform: translateX(-50%);
background-color: black;
color: white;
padding: 5px;
border-radius: 4px;
white-space: nowrap;
transition: opacity 0.3s ease-in-out;
}


.image-preview {
  margin-top: 15px;
  border: 2px solid #ddd;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.preview-img {
  max-width: 100%;
  max-height: 250px;
  border-radius: 8px;
}
.backLeftArrow{
  width: 20px;
  height: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .user-profile-card {
    margin: 20px;
  }

  .user-profile-header,
  .user-profile-details,
  .user-blogs {
    padding: 10px;
  }
}