/* src/ResponsiveForm.css */
.responsive-form {
    max-width: 600px;
    margin: 150px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.responsive-form .form-group {
    margin-bottom: 15px;
}

.responsive-form .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.responsive-form .form-group input,
.responsive-form .form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.responsive-form button {
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    border: none;
    border-radius: 4px;
    font-size: 16px;
}

.responsive-form button:hover {
    background-color: #218838;
}
