.blogsWrapper {
  background-image: url(../../../Assets/wetBlackSoilBlogs.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 450px;
}
.blogSearch {
  width: 36%;
}
.BlogPotImg {
  width: 30%;
}
.blogsPhrase {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: bolder;
  line-height: 1.2;
}
.searchBar {
  padding: 5px 11px;
  background-color: white;
  border-radius: 30px;
  margin-top: 50px;
}
.searchBar form input {
  width: 300px;
  padding: 10px;
}
.searchBar input:focus {
  outline: none;
}
.searchBar button {
  border-radius: 30px;
  height: 45px;
  padding: 0px 20px;
  background-color: #a3a3a3;
}
.blogsPotPlant {
  width: 300px;
  height: auto;
}
.crossSearch {
  background-color: rgb(221, 221, 221);
  top: 12px;
  right: 5px;
  width: 24px;
  height: 24px;
}
@media screen and (max-width: 767px) and (min-width: 0px) {
  .blogSearch {
    width: 80%;
  }
  .blogsPhrase {
    font-size: 25px;
  }
  .searchBar {
    padding: 5px 11px;
    background-color: white;
    border-radius: 30px;
    margin-top: 30px;
  }
  .searchBar form input {
    width: 68%;
    padding: 5px;
  }
  .searchBar button {
    border-radius: 25px;
    padding: 0px 10px;
    height: 35px;
    float: right;
  }
  .blogsPotPlant {
    display: none;
  }
  .BlogPotImg h1 {
    display: none;
  }
  .BlogPotImg {
    width: 0%;
  }
}

@media screen and (max-width: 1440px) and (min-width: 768px) {
  .blogSearch {
    width: 55%;
  }
  .BlogPotImg {
    width: 30%;
  }
  .blogsPhrase {
    font-size: 25px;
  }
  .searchBar input {
    /* width:100%; */
    padding: 5px;
  }
  .searchBar button {
    border-radius: 25px;
    padding: 0px 10px;
    height: 45px;
    font-size: 13px;
  }
  .searchBar {
    margin-top: 30px;
  }
  .blogsPotPlant {
    width: 200px;
  }
}
