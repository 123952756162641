@import url("https://fonts.googleapis.com/css2?family=Acme&display=swap");

.homeSection {
  margin: 0 auto;
  background-image: url("../../Assets/hiring_image.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 300px;
  background-position: top;
}
.homeAnimation {
  width: 260px;
  margin-left: 0 !important;
  height: auto;
}
.addJob {
  color: rgb(0, 0, 0);
  font-weight: 600;
  background-color: rgb(255, 255, 255);
  border: 1px black solid;
  margin-top: 10px;
  right: 0;
  top: 100px;
  border-radius: 10px;
  margin-right: 30px;
  z-index: 2;
}
.addJob:hover {
  color: white;
  background-color: black;
}

.careerContent {
  max-width: 700px;
  margin: 2px auto;
  padding: 10px;
}
.jobPin {
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  padding: 6px;
  font-size: 20px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.pageHeading {
  font-family: "Acme", sans-serif;
  color: white;
  text-shadow: rgb(0, 0, 0) 1px 1px;
}
.viewJobs {
  background-color: orange;
  border-radius: 20px;
  padding: 10px;
  width: 150px;
  box-shadow: orangered 7px 7px;
}

.jobCard {
  width: 280px;
  max-width: 700px;
  height: auto;
}
.jobTitle {
  color: rgba(255, 69, 0, 0.8) !important;
  font-weight: 630;
}
.card-text {
  font-weight: 490;
}

.jobCard a {
  border-radius: 10px;
}
.jobCardsBody {
  width: 100%;
}
@media screen and (min-width: 425px) {
  .jobCard a {
    width: 200px;
  }
  .allcards {
    grid-template-columns: 1fr;
  }
  .jobCard {
    width: 400px;
  }
}
@media screen and (min-width: 768px) {
  .homeAnimation {
    width: 300px;
  }
  .allcards {
    /* margin-left: calc((100% - 700px) / 2); */
    /* grid-template-columns: 1fr; */
  }
  .jobCard {
    width: 700px;
  }
}
@media screen and (min-width: 992px) {
  .homeAnimation {
    width: 350px;
    left: 60px;
  }
  /* .allcards {
    margin-left: calc((100% - 700px) / 2);
    grid-template-columns: 400px 400px;
    justify-content: center;
  }
  .jobCard{
    width: 400px;
  } */
  .allcards > .card {
    margin-left: calc((100% - 900px) / 2);
    margin-right: calc((100% - 900px) / 2);
  }
  .jobCard {
    width: 900px;
  }
}
@media screen and (min-width: 1200px) {
  .homeAnimation {
    left: 150px;
  }
  /* .allcards {
    grid-template-columns: 580px 580px;
    justify-content: center;
  }
  .jobCard{
    width: 550px;
  } */
}
