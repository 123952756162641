
.aboutWebsite{
    display:flex;
    flex-direction: column;
    align-items: center;
}
.context{
    width: 65%;
    padding: 30px;
    margin-top:50px
}

.whatYouGet{
    width: 65%;
    padding: 30px 30px 0px 30px;

}
.wetSoilBlack{
    width: 100%;
    height: auto;
}
@media screen and (max-width:767px) and (min-width:320px) {
    .context{
        width: 90%;
        padding: 10px;
        margin-top:25px
    }
    .whatYouGet{
        width: 90%;
        padding: 10px 10px 0px 10px;
    
    }
}