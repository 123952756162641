.postEditorPage {
  padding: 100px;
  padding-left: 0;
  padding-top: 20px;
}
.backButton {
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
  color: black;
  background-color: white;
  border: 1px black solid;
  position: absolute;
  left: 0;
  text-align: center;
  margin-left: 30px;
}
.backButton:hover {
  color: white;
  background-color: black;
}
.jodit-workplace {
  min-height: 500px !important;
  max-height: 630px !important;
  overflow-y: scroll !important;
}

.wrapDeleteBtns {
  width: 150px;
  height: 50px;
  overflow: hidden;
}

.wrapDeleteBtns .w-75 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.postEditor {
  min-width: 600px;
  max-width: 1000px;
  min-height: 400px;
  padding: 10px;
  border: 1px solid rgb(233, 221, 221);
  box-shadow: 1px 2px 5px;
  background-color: rgb(228, 221, 221);
  border-radius: 10px;
}
.description {
  min-height: 500px;
}
.editorBtns {
  position: fixed;
  right: 20px;
  z-index: 3;
}
#button-addon2 {
  height: 100%;
  margin-top: 10px;
}
.pagination {
  margin: 20px auto;
}

.pagination a {
  color: black;
  cursor: pointer;
  margin: 0;
  padding: 5px 10px;
  border: 1px solid black;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.pagination a:hover {
  background-color: rgb(121, 121, 121);
  color: white !important;
}
.active a {
  background-color: rgb(10, 10, 10);
  color: white !important;
  text-decoration: none;
}

.hideReason {
  top: -200px;
  transition: all ease 1s;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
.shownReason {
  top: 10px;
  transition: all ease 1s;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.animateAlert {
  top: 10px;
  transition: all ease 1s;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
.hideAlert {
  top: -200px;
  transition: all ease 1s;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.positive {
  background-color: rgb(77, 207, 77);
}
.negative {
  background-color: red;
}
.seoFormWrapper{
  z-index: 10;
  height: 100%;
  background-color: rgb(56, 56, 56,0.3);

  font-family: "Montserrat", sans-serif;
  transition: all ease 1s;
}
.seoform{
width: 60%;
}
.seoform input,.seoform textarea{
width: 100%;
resize: none;
border: 1px solid black;
}
.seoform input:focus,.seoform textarea:focus{
  outline: none;
} 
.rejectReason{
  resize: none;
}
.rejectReason:focus{
  outline: none;
}


/* Base button styles */
.statBtn {
  color: white; /* White text */
  border: none; /* Remove default border */
  padding: 10px 20px; /* Add some padding */
  font-size: 16px; /* Increase font size */
  font-weight: bold; /* Bold text */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
  border-radius:4px
}

.statBtn:hover {
  transform: translateY(-2px); /* Slight lift on hover */
}

.statBtn:active {
  transform: translateY(0); /* Reset lift on click */
}

.statBtn:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2); /* Custom focus outline */
}

/* Approve button */
.statApproveBtn {
  background-color: #28a745; /* Green background */
}

.statApproveBtn:hover {
  background-color: #218838; /* Darker green on hover */
}

.statApproveBtn:active {
  background-color: #1e7e34; /* Even darker green on click */
}

.statApproveBtn:focus {
  box-shadow: 0 0 0 2px rgba(40, 167, 69, 0.5); /* Custom focus outline */
}

/* Reject/Review button */
.statReviewBtn {
  background-color: #dc3545; /* Red background */
}

.statReviewBtn:hover {
  background-color: #c82333; /* Darker red on hover */
}

.statReviewBtn:active {
  background-color: #bd2130; /* Even darker red on click */
}

.statReviewBtn:focus {
  box-shadow: 0 0 0 2px rgba(220, 53, 69, 0.5); /* Custom focus outline */
}

/* Pending button */
.statPendingBtn {
  background-color: #ffc107; /* Yellow background */
  color: black; /* Black text */
}

.statPendingBtn:hover {
  background-color: #e0a800; /* Darker yellow on hover */
}

.statPendingBtn:active {
  background-color: #d39e00; /* Even darker yellow on click */
}

.statPendingBtn:focus {
  box-shadow: 0 0 0 2px rgba(255, 193, 7, 0.5); /* Custom focus outline */
}

/* Draft button */
.statDraftBtn {
  background-color: #6c757d; /* Gray background */
}

.statDraftBtn:hover {
  background-color: #5a6268; /* Darker gray on hover */
}

.statDraftBtn:active {
  background-color: #545b62; /* Even darker gray on click */
}

.statDraftBtn:focus {
  box-shadow: 0 0 0 2px rgba(108, 117, 125, 0.5); /* Custom focus outline */
}

/* Send to Admin button */
.statSendAdminBtn {
  background-color: #007bff; /* Blue background */
}

.statSendAdminBtn:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.statSendAdminBtn:active {
  background-color: #004085; /* Even darker blue on click */
}

.statSendAdminBtn:focus {
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Custom focus outline */
}





.statUpdateBtn{
      display: inline-block;
      outline: 0;
      border: 0;
      cursor: pointer;
      will-change: box-shadow,transform;
      box-shadow: 0px 2px 4px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px rgb(58 65 111 / 50%);
      padding: 0 32px;
      border-radius: 6px;
      color: #fff;
      height: 48px;
      font-size: 18px;
      text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
      transition: box-shadow 0.15s ease,transform 0.15s ease;
    }
    .statUpdateBtn{
      background: radial-gradient( 100% 100% at 100% 0%, #89E5FF 0%, #5468FF 100% );
    }
    .statUpdateBtn:hover {
      box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #5468FF;
      transform: translateY(-2px);
    }
  .statUpdateBtn:active{
      box-shadow: inset 0px 3px 7px #3c4fe0;
      transform: translateY(2px);
  }


  

@media screen and (min-width: 992px) {
  .postEditor {
    width: 800px;
  }
  .seoform{
    width: 30%;
  }
}
@media screen and (min-width: 1200px) {
  .postEditor {
    width: 1000px;
  }
}
