.farmingBanner{
    background-image: url(../../Assets/HomeBanner.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    object-fit: cover;
   
}
.farmingContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    z-index: 1;
}
.farmerMotoPhrase{
    font-size: 45px;    
    width: 50%;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    color: #15db12;
}
.FarmImprovementHub{
    width: 35%;
    color: white;
}
.transpLayer{
    position: absolute;
    top:0;
    left:0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index:-1;
}
.disCoverFarmersWealth{
    padding: 15px 20px;
    background-color: #C4FF78;
    border-radius: 30px;
    border:3px solid white;
    margin-top: 60px;
    text-decoration: none;
}
@media screen and (max-width:767px) and (min-width:0px) {
    .farmerMotoPhrase{
        font-size: 18px;
        width: 90%;
    }
    .FarmImprovementHub{
        width: 80%;
    }
    .disCoverFarmersWealth{
        padding: 10px 15px;
    }
    .farmingBanner{
        height: 600px;
    }
    .transpLayer{
        height: 600px;
    }
}
@media screen and (max-width:1024px) and (min-width:768px) {
    .farmerMotoPhrase{
        font-size: 30px;
        width: 60%;
    }
    .FarmImprovementHub{
        width: 60%;
    }
    .disCoverFarmersWealth{
        padding: 10px 15px;
        margin-top: 60px;
    }
    .farmingBanner{
        height: 600px;
    }
    .transpLayer{
        height: 600px;
    }
    .disCoverFarmersWealth{
        padding: 10px 15px;
        margin-top: 50px;
    }
}