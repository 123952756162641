.move-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff; /* Change color as per your preference */
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: opacity 0.4s;
    z-index: 1000;
  }
  
  .move-to-top-button:hover {
    opacity: 0.8;
  }
  