.loginWrapper {
    min-height: 100vh;
    background-color: #f8f9fa;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .loginContentWrapper {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    position: relative; /* Add position relative */
  }
  
  .loginContentForm {
    width: 100%;
  }
  
  .jaikisanLogo {
    position: absolute;
    top: -45px;
    left: 10px;
    transform: translateX(-50%);
    width: 60px;
    height: auto;
  }
  
  .jaikisanLogoImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .labelHeadings {
    font-weight: bold;
  }
  
  .form-control {
    border-radius: 5px;
    border: 1px solid #ced4da;
    margin-top: 10px; /* Add margin top */
  }
  
  .loginBtn {
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .loginBtn:hover {
    background-color: #0056b3;
  }
  @media screen and (max-width:767px)  and (min-width:0px){
   
      .jaikisanLogo {
        position: absolute;
        top: -50px;
        left: 50px;
        transform: translateX(-50%);
        width: 60px;
        height: auto;
      }
      
  }