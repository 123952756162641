.blog-card-wrapper {
  min-height: 115vh;
  margin-top: 50px;
  font-family: "Montserrat", sans-serif;
  background-color: #f5f5f5;
}

.onBlogPublishDate {
  font-family: "Jost", sans-serif;
  background-color: #e2e2e2;
}

.blog-card {
  width: 18rem;
  height: 25rem;
  /* box-shadow: -1px 0px 5px 0px rgba(192, 192, 192, 0.75);
  -webkit-box-shadow: -1px 0px 5px 0px rgba(192, 192, 192, 0.75);
  -moz-box-shadow: -1px 0px 5px 0px rgba(192, 192, 192, 0.75); */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.card-img-top {
  width: 85% !important;
  height: 40%;
  top: -20px;
  /* object-fit: contain; */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.176);
}
.blogImgWrapper {
  height: 9rem;
}
.blogPreviewContent {
  padding: 15px !important;
  margin-bottom: 30px;
  margin-top: 30px;
}

.blogVisitBtn {
  background-color: #cacaca;
  text-decoration: none;
  padding: 5px 15px;
  bottom: 15px;
}

.addBlog {
  top: 100px;
  right: 20px;
  cursor: pointer;
  background-color: rgb(221, 221, 221);
  text-decoration: none;
  z-index: 2;
}

.addUser {
  top: 150px;
  right: 20px;
  cursor: pointer;
  background-color: rgb(221, 221, 221);
  text-decoration: none;
  z-index: 2;
}

.pin {
  top: 0;
  width: 30px;
  height: 30px;
  padding: 6px;
  font-size: 20px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.deletePin {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: rgb(231, 194, 138);
  width: 30px;
  height: 30px;
  padding: 6px;
  font-size: 20px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.pin:hover,
.deletePin:hover {
  cursor: pointer;
}

.blogCards {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 5rem;
}

.publisedName {
  font-size: 14px;
}

.blogPreview {
  font-size: 14px;
  line-break: auto;
}

@media screen and (max-width: 767px) and (min-width: 0px) {
  .blogCards {
    grid-template-columns: 1fr;
  }

  .addUser {
    font-size: 10px;
    right: 0px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .blogCards {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 1439px) and (min-width: 1024px) {
  .blogCards {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 2560px) and (min-width: 1440px) {
  .blogCards {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
